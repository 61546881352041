import React from "react";
import InfoDisplay from "./components/InfoDisplay";

function App() {
  return (
    <div className="App">
      <InfoDisplay />
    </div>
  );
}

export default App;
