import axios from "axios";
import { useEffect, useState } from "react";

export const useFetch = (url, transformData) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!url) {
      setLoading(false);
      return;
    }
    setLoading(true);
    axios
      .get(url)
      .then((response) => {
        const responseData = transformData
          ? transformData(response.data)
          : response.data;
        setData(responseData);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, [url, transformData]);

  return { data, loading, error };
};

export default useFetch;
