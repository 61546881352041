import React from "react";
import useFetch from "../hooks/useFetch";

function InfoDisplay() {
    const linkStyle = {
      color: "white", 
      fontFamily: "Arial", 
    };
  const serverIpUrl = "https://api-dns.mionet.top/resolve-ip";
  const {
    data: serverIpData,
    loading: serverIpLoading,
    error: serverIpError,
  } = useFetch(serverIpUrl);

  const apiKey = "d700959dbfd212";
  const ip = serverIpData ? serverIpData.ip : null;
  const countryUrl = ip ? `https://ipinfo.io/${ip}/json?token=${apiKey}` : null;
  const {
    data: countryData,
    loading: countryLoading,
    error: countryError,
  } = useFetch(countryUrl);

  const countryCode = countryData ? countryData.country : null;
  const countryNameUrl = countryCode
    ? `https://restcountries.com/v3.1/alpha/${countryCode}`
    : null;
  const {
    data: countryNameData,
    loading: countryNameLoading,
    error: countryNameError,
  } = useFetch(countryNameUrl);

  const unsplashKey = "PNLgtvVkVvlcaChh-sW7wDyUFBxKIX1TZ_fFzB2zc5Q";
  const countryName = countryNameData ? countryNameData[0].name.common : null;
  const backgroundUrl = countryName
    ? `https://api.unsplash.com/search/photos?page=1&query=${countryName}&client_id=${unsplashKey}`
    : null;
  const {
    data: imageData,
    loading: imageLoading,
    error: imageError,
  } = useFetch(backgroundUrl);

  if (serverIpLoading || countryLoading || countryNameLoading || imageLoading)
    return <p>Loading...</p>;
  if (serverIpError)
    return <p>Error loading server IP: {serverIpError.message}</p>;
  if (countryError)
    return <p>Error loading country data: {countryError.message}</p>;
  if (countryNameError)
    return <p>Error loading country name: {countryNameError.message}</p>;
  if (imageError) return <p>Error loading image: {imageError.message}</p>;
  if (!imageData || imageData.results.length === 0)
    return <p>No images found.</p>;

  const imageUrl = imageData.results[0].urls.regular;

  return (
    <div
      style={{
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
        width: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
      }}
    >
      <h1>Location: {countryName || "Unknown"}</h1>
      <h2>City: {countryData ? countryData.city : "Unknown"}</h2>
      <h3>IP: {ip}</h3>
      <footer>
        This is an experimental project developed by Mio. If you have any
        issues, please{" "}
        <a href="https://t.me/Nenechan0721" style={linkStyle}>
          contact
        </a>{" "}
        me.
      </footer>
    </div>
  );
}

export default InfoDisplay;
